import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { capitalize, flatMap, get, isBoolean, noop, uniqBy } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactSwitch from "react-switch";
import Global from "../../Global";
import colors from "../../colors.json";
import { DashboardTile } from "../../components/dashboard-tile/DashboardTile";
import FilterEditor from "../../components/filter-editor/FilterEditor";
import Menu from "../../components/menu/Menu";
import { IModal } from "../../components/modal/Modal";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import { ValueSpinner } from "../../components/value-spinner/ValueSpinner";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { DashboadTileSettings, DashboardSettingsType, SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { useDeviationTimeperiodStatistics } from "../../hooks/UseDeviationTimeperiodStatistics";
import { toGridLayout, useGridLayout } from "../../hooks/UseGridLayout";
import { useStatistics } from "../../hooks/UseStatistics";
import { AggregatedTimeperiodElementSchema, AggregatedTimeperiodSchema, useTimeAggregatedCaseStatistics } from "../../hooks/UseTimeAggregatedCaseStatistics";
import i18n from "../../i18n";
import { CustomKpi, StatsCalculationRequest, TimePeriodFrequencies, ViewConfigurationType, disableAllCalcOptions } from "../../models/ApiTypes";
import { Point } from "../../models/Dfg";
import { KpiDefinition, TimeperiodApis, decideTimeperiodApi, getKpiDefinition, getProductStatisticPath, getTimeperiodStatisticPath, getUnit } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { buildAttributeFilter } from "../../utils/FilterBuilder";
import { Formatter, UnitMetadata, getLongUnit } from "../../utils/Formatter";
import { ShareModal, shareAsync } from "../../utils/Share";
import { addStep, floorTime, timestampSort, toUserTimezone, toUserTimezoneMillis } from "../../utils/TimezoneUtils";
import updateUserpilotUrl from "../../utils/Userpilot";
import { classNames, getHash, isNiceNumber } from "../../utils/Utils";
import { EditFavoritesModal } from "../favorites/EditFavoritesModal";
import { DashboardTileSettingsModal, getContextFromTile, getContextOverride } from "./DashboardTileSettingsModal";
import { getPlanningState } from "../../utils/SettingsUtils";
import Spinner from "../../components/spinner/Spinner";
import { useTimeAggregatedEventStatistics } from "../../hooks/UseTimeAggregatedEventStatistics";
import { Api } from "../../api/Api";

type TileModel = DashboadTileSettings & {
    kpiDefinition: KpiDefinition,
    endpoint: TimeperiodApis,
};

const durationLimits = {
    [TimePeriodFrequencies.Hour]: 100,
    [TimePeriodFrequencies.Day]: 28,
    [TimePeriodFrequencies.Week]: 24,
    [TimePeriodFrequencies.Month]: 24,
    [TimePeriodFrequencies.Year]: 10,
};

const initialFrequencies = [{
    frequency: TimePeriodFrequencies.Year,
    minSeconds: 3 * 365 * 86400,
}, {
    frequency: TimePeriodFrequencies.Month,
    minSeconds: 3 * 31 * 86400,
}, {
    frequency: TimePeriodFrequencies.Week,
    minSeconds: 3 * 7 * 86400,
},
{
    frequency: TimePeriodFrequencies.Hour,
    minSeconds: 3 * 3600,
}];

export function Dashboard() {
    const { projectId } = useParams<{
        projectId: string,
    }>();

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    session.setProject(projectId);

    const { trackEvent } = useMatomo();

    updateUserpilotUrl();

    const [selectedTileIdx, setSelectedTileIdx] = useState<number | undefined>(undefined);
    const [tileEditIdx, setTileEditIdx] = useState<number | undefined>(undefined);
    const [showShareModal, setShowShareModal] = useState(false);

    const addFavoritesModalRef = useRef<IModal>(null);

    // Perform grid layout
    const containerRef = useRef<HTMLDivElement>(null);
    const tileSize = useGridLayout(containerRef, 6, {
        // When changing, remember to check that dashboard texts for main languages do not break on any screen sizes
        // Typical screen sizes should still see 3 tile columns
        minWidth: 350,
        minHeight: 250,
        desiredHeight: 300,
        xGap: 8  // needs to be about the same as the gap in the css
    });
    const tileLayout = toGridLayout(tileSize);

    const [allStats, isAllStatsLoading] = useStatistics();

    const { hasRoutings, hasPlanning, showPlanningData } = getDashboardPlanningState(session, settings);

    // The dashboard state is primarily stored in the settings context.
    // However, shared views may overwrite these!
    useEffect(() => {
        if (session.project === undefined)
            return;

        fetchDashboardSettings(session, settings, projectId);
    }, [
        session.project,
        JSON.stringify(settings.dashboard),
    ]);

    // We use an empty filter list here because we are refering to the stats of the whole dataset.
    const [stats, isStatsLoading] = useStatistics([], {
        onData: (data) => {
            // Only initialize frequency if previously unset
            if (settings.dashboard?.frequency !== undefined)
                return;

            const durationSeconds = ((data.maxDate?.getTime() ?? 0) - (data.minDate?.getTime() ?? 0)) / 1000;
            if (durationSeconds === 0)
                return;

            let initialFrequency = TimePeriodFrequencies.Day;
            for (const f of initialFrequencies)
                if (durationSeconds > f.minSeconds) {
                    initialFrequency = f.frequency;
                    break;
                }

            queueMicrotask(() => {
                settings.mergeSet({
                    dashboard: {
                        frequency: initialFrequency,
                    },
                });
            });
        }
    });

    // Display data up until this timestamp
    const endDate = (() => {
        if (!stats.maxDate || !settings.dashboard?.frequency)
            return;

        const maxDate = toUserTimezoneMillis(stats.maxDate.getTime(), session.timezone);
        const prev = floorTime(stats.maxDate.getTime(), settings.dashboard.frequency, session.timezone);
        const next = addStep(prev, session.timezone, settings.dashboard.frequency, false, 1);

        // if maximum date is equal to next, use that as our end date. Otherwise, use prev.
        return (timestampSort(maxDate, next) === 0) ? next : prev;
    })();


    // make stable reference so we can use this as a dependency in useMemo or useEffect.
    // also, annotate which tile is requested using which API
    const tileDefs = useMemo(() => {
        return (settings.dashboard?.tiles ?? getDefaultTiles(session, settings) ?? []).map(t => {
            if (t.kpiType === KpiTypes.CarbonPerOutput)
                return {
                    ...t,
                    kpiType: KpiTypes.Carbon,
                };
            if (t.kpiType === KpiTypes.EnergyPerOutput)
                return {
                    ...t,
                    kpiType: KpiTypes.Energy,
                };
            return t;
        }).map(t => {
            return {
                ...t,
                endpoint: decideEndpoint(session, settings, t) ?? TimeperiodApis.Case,
                kpiDefinition: t.kpiType !== undefined ? getKpiDefinition(t.kpiType, getContextFromTile(t, session, settings)) : undefined,
            };
        }) as TileModel[];
    }, [
        session,
        showPlanningData,
        JSON.stringify(settings.dashboard?.tiles)
    ]);

    // Request planning helper
    // Dictionary: Endpoint, WIP => tile
    function getKey(endpoint: TimeperiodApis, wip: boolean) {
        return `${endpoint}-${wip}`;
    }

    const requestPlan: {
        [key: string]: {
            tiles: TileModel[],
            data: AggregatedTimeperiodSchema | undefined,
            isLoading: boolean,
            hash?: string,
        }
    } = {};

    for (const tile of tileDefs) {
        if (!tile?.kpiDefinition)
            continue;
        const key = getKey(tile.endpoint, !!tile.kpiDefinition.isWipIncluded);
        if (!requestPlan[key])
            requestPlan[key] = {
                tiles: [],
                data: undefined,
                isLoading: true,
            };

        requestPlan[key]!.tiles.push(tile);
    }


    // Actually execute batched requests
    const isInitializing = session.project === undefined || settings.dashboard?.frequency === undefined || stats === undefined;

    const wipExcludedFilter = session.project?.eventKeys?.isWip ? buildAttributeFilter(session, session.project?.eventKeys?.isWip, "true", true) : undefined;

    let isSomeTileLoading = false;

    // Helper function that takes care of adding data to the request plan
    const appendData = (key: string, arr: [AggregatedTimeperiodSchema | undefined, boolean, string | undefined]) => {
        // The dashboard should show only "complete" datapoints. Meaning, that if the maximum timestamp
        // from all cases is within e.g. a week, this week is not considered complete and removed
        // from the charts. We make an exception from this rule only for daily or hourly data because the time
        // period is very short anyway.
        const filterFunc = settings.dashboard?.frequency === TimePeriodFrequencies.Day || settings.dashboard?.frequency === TimePeriodFrequencies.Hour ? () => true :
            (t: AggregatedTimeperiodElementSchema) =>
                timestampSort(toUserTimezone(t?.timeperiodStartTime, session.timezone), endDate) < 0;

        const e = requestPlan[key];
        if (!e)
            return;

        e.data = !arr[0] ? undefined : {
            ...arr[0],
            timeperiods: arr[0].timeperiods?.filter(filterFunc),
        };
        e.isLoading = arr[1] || isInitializing;
        e.hash = arr[2];
    };

    for (const includeWip of [false, true]) {
        for (const endpoint of [TimeperiodApis.Case, TimeperiodApis.CaseDeviation, TimeperiodApis.Event]) {
            const key = getKey(endpoint, includeWip);
            const tiles = requestPlan[key]?.tiles;
            const filters = settings.previewFilters ?? settings.filters;
            const wipFilters = wipExcludedFilter ? [wipExcludedFilter] : [];
            const requestOptions = {
                frequency: settings.dashboard?.frequency,
                sort: ["-timeperiodStartTime"],
                limit: durationLimits[settings.dashboard?.frequency ?? TimePeriodFrequencies.Week],
                ...disableAllCalcOptions,
                customKpis: getCustomKpis(tiles),
                ...getCalculateOptions(tiles),
                eventFilters: [...filters].concat(wipFilters),
            };
            const hookOptions = { disable: isInitializing || !tiles?.length, addEnergyStats: true, hash: true, };
            const calculatePlanned = showPlanningData && hasRoutings;

            switch (endpoint) {
                case TimeperiodApis.Case: {
                    appendData(key, useTimeAggregatedCaseStatistics({ ...requestOptions, calculatePlanned }, hookOptions));
                    break;
                }
                case TimeperiodApis.CaseDeviation: {
                    appendData(key, useDeviationTimeperiodStatistics(requestOptions, hookOptions));
                    break;
                }
                case TimeperiodApis.Event: {
                    appendData(key, useTimeAggregatedEventStatistics({ ...requestOptions, calculatePlanned }, hookOptions));
                    break;
                }
            }

            isSomeTileLoading = isSomeTileLoading || requestPlan[key]?.isLoading;
        }
    }

    const dataHash = getHash(flatMap(Object.keys(requestPlan), k => requestPlan[k].hash ?? []).sort());
    const loadingHash = getHash(flatMap(Object.keys(requestPlan), k => requestPlan[k].isLoading ? k : []).sort());

    // Get the last x value
    const lastXValue = Object.keys(requestPlan).map(k => {
        const t = requestPlan[k].data?.timeperiods?.[0]?.timeperiodStartTime;
        if (!t)
            return undefined;
        return toUserTimezone(t, session.timezone);
    }).filter(t => t !== undefined).sort((a, b) => {
        return -timestampSort(a, b);
    })[0];

    // Render tiles
    const { dashboardTiles } = useMemo(() => {
        const dashboardTiles: JSX.Element[] = [];

        for (let idx = 0; idx < tileDefs.length; idx++) {
            const tile = tileDefs[idx];

            if (!tile.kpiDefinition) {
                dashboardTiles.push(getEmptyTile(idx));
                continue;
            }

            const key = getKey(tile.endpoint, !!tile.kpiDefinition.isWipIncluded);

            const data = requestPlan[key]?.data;
            const isLoading = requestPlan[key]?.isLoading ?? true;
            if (isLoading) {
                dashboardTiles.push(getLoadingTile(settings, tile, idx));
                continue;
            }

            const unit = getUnit(tile.kpiDefinition.unit, tile.statistic) ?? tile.kpiDefinition.unit as UnitMetadata;
            const scale = unit.name === "percent" ? 100 : 1;
            const localPath = getTimeperiodStatisticPath(tile.kpiDefinition, tile.statistic) ??
                getProductStatisticPath(tile.kpiDefinition, tile.statistic);

            const times = data?.timeperiods.map(t => new Date(t.timeperiodStartTime).getTime()) ?? [];

            const values = getTimeseries(data?.timeperiods, `actual.${localPath}`, scale, times);
            const planValues = !showPlanningData || !tile.kpiDefinition.allowedComparisons.includes(KpiComparisons.Planning) ? [] :
                getTimeseries(data?.timeperiods, `planned.${localPath}`, scale, times);

            const value = values[0]?.y;
            const prevValue = values[1]?.y;
            const planValue = planValues[0]?.y;

            dashboardTiles.push(<DashboardTile
                key={`tile-${tile?.kpiType ?? ""}-${tile?.quantity ?? ""}-${tile?.statistic}-${idx}`}
                spotlight={`${tile.kpiDefinition.spotlightId}-Dashboard-${capitalize(tile.statistic)}`}
                statistic={tile.statistic}
                values={values}
                planValues={planValues}
                kpiType={tile.kpiType}
                value={value}
                planValue={planValue}
                prevValue={prevValue}
                isLoading={isLoading}
                unit={unit}
                scales={getLongUnit(unit).getUnits({
                    baseQuantity: tile.quantity,
                })}
                isLessBetter={tile.kpiDefinition.isLessBetter}
                title={i18n.t(tile.kpiDefinition.label).toString()}
                xTickFrequency={settings.dashboard?.frequency}
                onSettingsClick={() => {
                    setTileEditIdx(idx);
                }}
                isSelected={Global.isTouchEnabled && idx === selectedTileIdx}
                onClick={() => {
                    setSelectedTileIdx(selectedTileIdx === idx ? undefined : idx);
                }}
            />);
        }

        return { dashboardTiles };
    }, [
        tileDefs,
        settings.dashboard?.frequency,
        showPlanningData,
        dataHash,
        loadingHash,
        selectedTileIdx,
    ]);

    // Generate view
    const isNoDataAvailable = !isAllStatsLoading && allStats.numFilteredTraces === 0;
    const isLoading = !isNoDataAvailable && (isSomeTileLoading || isStatsLoading || !lastXValue);

    const [showProjectLoadingSpinner, setShowProjectLoadingSpinner] = useState(!Global.projectLoadingSpinnerHasBeenShown);
    useEffect(() => {
        if (isLoading || !lastXValue || !session.project)
            return;

        setShowProjectLoadingSpinner(false);
    }, [
        isLoading
    ]);
    const showProjectLoadingSpinnerInsteadOfDashboard = !session.projectId || showProjectLoadingSpinner && isLoading;

    const setFrequency = (f: TimePeriodFrequencies) => settings.mergeSet({ dashboard: { frequency: f } });

    return <div className={classNames(["dashboard", "dashboardFilter"])}>
        <Spinner isLoading={showProjectLoadingSpinnerInsteadOfDashboard} text="common.projectInitializing" />
        <div className={classNames(["dashboardContainer", settings.filterEditor.showFilterEditor && "filterExpanded", showProjectLoadingSpinnerInsteadOfDashboard && "hidden"])} ref={containerRef}>
            <div className="dashboardContent">
                <div className="pageHeader">
                    <div className="greeting">
                        {i18n.t("common.dashboard")}
                    </div>

                    {!isNoDataAvailable && <div className="buttons">
                        {/* Compare with plan */}
                        {hasPlanning && <div>
                            <div className="buttonesque"
                                onClick={() => settings.mergeSet({
                                    dashboard: {
                                        // invert current state
                                        showPlanningData: !showPlanningData,
                                    },
                                })}>
                                <ReactSwitch
                                    id="switch-planning-comparison"
                                    height={16}
                                    handleDiameter={12}
                                    width={27}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offColor={colors["$gray-2"]}
                                    onColor={colors["$primary-500"]}
                                    checked={showPlanningData}
                                    onChange={noop}
                                />
                                <label className="clickable">
                                    {i18n.t("common.planComparison")}
                                </label>
                            </div>
                        </div>}

                        {/* Time scale */}
                        <Menu className="menuLight" items={[{
                            title: i18n.t("units.hour").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Hour); }
                        }, {
                            title: i18n.t("units.day").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Day); }
                        }, {
                            title: i18n.t("units.week").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Week); },
                        }, {
                            title: i18n.t("units.month").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Month); },
                        }, {
                            title: i18n.t("units.year").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Year); }
                        }]}>
                            <div className="buttonesque" data-testid="frequency-selection">
                                <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-calendar" /></svg>
                                <ValueSpinner isLoading={settings.dashboard?.frequency === undefined}>
                                    <>
                                        {settings.dashboard?.frequency !== undefined && i18n.t("units." + settings.dashboard.frequency.toString())}
                                    </>
                                </ValueSpinner>
                            </div>
                        </Menu>

                        <Menu className="menuLight" items={[{
                            title: i18n.t("dashboard.resetDashboard").toString(),
                            onClick: async () => { await resetDashboardSettings(session, settings, projectId); }
                        }]}>
                            <div className="buttonesque">
                                <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-gear" /></svg>
                            </div>
                        </Menu>

                        <div className="buttonesque" title={i18n.t("favorites.saveView").toString()} onClick={() => {
                            addFavoritesModalRef.current?.show();
                        }}>
                            <svg className="svg-icon xsmall clickable brandHover">
                                <use xlinkHref="#radix-star" />
                            </svg>
                        </div>
                        {session.project?.isSharedWithOrganization && <div className="buttonesque" title={i18n.t("common.shareView").toString()}
                            onClick={async () => {
                                const sharingWorked = await shareAsync(session, settings);

                                trackEvent({
                                    category: "Interactions",
                                    action: "Shared view",
                                    name: sharingWorked ? "navigator" : "fallback",
                                });

                                setShowShareModal(!sharingWorked);
                            }}>
                            <svg className="svg-icon xsmall clickable brandHover">
                                <use xlinkHref="#radix-share-1" />
                            </svg>
                        </div>}
                    </div>}
                </div>
                <div className="grid">
                    {(dashboardTiles?.length ?? 0) > 0 && !isNoDataAvailable && <div className="tilesContainer" style={{ ...tileLayout }}>
                        {dashboardTiles}
                    </div>}
                    <NoDataAvailable
                        message="dashboard.noDataAvailable"
                        visible={isNoDataAvailable}
                    />
                </div>
            </div>

            <FilterEditor />
        </div>

        <EditFavoritesModal ref={addFavoritesModalRef} />

        {showShareModal && <ShareModal onDone={async () => setShowShareModal(false)} />}

        {tileEditIdx !== undefined && <DashboardTileSettingsModal
            initialValue={tileDefs?.[tileEditIdx ?? 0]}
            onCancel={() => {
                setTileEditIdx(undefined);
            }}
            onChange={(kpi, statistic, quantity) => {
                const newTileDefs = (tileDefs ?? []).map((t, idx) => {
                    if (idx !== tileEditIdx)
                        return t;

                    return {
                        kpiType: kpi,
                        statistic: statistic,
                        quantity: quantity,
                    };
                });
                setTileEditIdx(undefined);
                settings.mergeSet({
                    dashboard: {
                        tiles: newTileDefs,
                    },
                });
            }}
        />}
    </div>;


    function getEmptyTile(idx: number) {
        return <div className="dashboardTile" key={`empty-tile-${idx}`}>
            <div className="header">
                <div className="title">
                    <svg className="svg-icon xxsmall clickable brandHover edit" data-testid="tile-options" onClick={(e) => {
                        setTileEditIdx(idx);
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <use xlinkHref="#radix-pencil-1" />
                    </svg>
                </div>
            </div>
        </div>;
    }
}

/**
 * Loads dashboard settings from local storage
 * @returns DashboardSettingsType instance or undefined
 */
export function getDashboardSettings(session: SessionType, projectId: string | undefined) {
    const tileSettingsKey = `${session.user?.hash ?? "?"}/settings/${projectId ?? session.projectId}/dashboard-ng`;
    const json = localStorage.getItem(tileSettingsKey);
    if (json)
        try {
            return JSON.parse(json) as DashboardSettingsType;
        } catch (e) {
            // Ignore
        }
    return undefined;
}

/**
 * Serializes the dashboard settings into local storage
 */
function writeDashboardSettings(session: SessionType, settings: SettingsContextType, projectId?: string) {
    const tileSettingsKey = `${session.user?.hash ?? "?"}/settings/${projectId ?? session.projectId}/dashboard-ng`;
    const json = JSON.stringify({
        ...settings.dashboard,
        // prevent internal state to leak into local storage
        tiles: !settings.dashboard?.tiles ? undefined :
            settings.dashboard.tiles.map(t => {
                return {
                    kpiType: t.kpiType,
                    statistic: t.statistic,
                    quantity: t.quantity,
                };
            }),
    });
    localStorage.setItem(tileSettingsKey, json);
}

/**
 * Decides for a given tile, if the deviation or case endpoint should be used.
 * Not sure but this function could probably be collapsed to
 * return tile.kpiDefinition.timeperiodApi ?? TimeperiodApis.Case;
 * @returns Endpoint type or undefined if the tile cannot be displayed
 */
function decideEndpoint(session: SessionType, settings: SettingsType, tile: DashboadTileSettings) {
    const { showPlanningData } = getDashboardPlanningState(session, settings);
    const context = getContextFromTile(tile, session, settings);
    const kpiDef = getKpiDefinition(tile.kpiType, context);
    return decideTimeperiodApi(context.session, kpiDef, showPlanningData, tile.quantity);
}


export function getDefaultTiles(session: SessionType, settings: SettingsType) {
    const { hasPlanningLog } = getDashboardPlanningState(session, settings);
    const result = [
        { type: KpiTypes.ThroughputTime, statistic: StatisticTypes.Mean },
        { type: KpiTypes.ProductionProcessRatio, statistic: StatisticTypes.Mean },
        { type: KpiTypes.QueuingTime, statistic: StatisticTypes.Mean },
        { type: KpiTypes.ThroughputRate, statistic: StatisticTypes.Mean },
        { type: KpiTypes.ScrapRatio, statistic: StatisticTypes.Mean },
        { type: KpiTypes.Carbon, statistic: StatisticTypes.Mean },
        { type: KpiTypes.GoodQuantity, statistic: StatisticTypes.Sum },
        { type: KpiTypes.DeviationThroughputTime, statistic: StatisticTypes.Mean },
        { type: KpiTypes.OnTimeDelivery, statistic: StatisticTypes.Mean },
        { type: KpiTypes.OrderCount, statistic: StatisticTypes.Sum },
    ].map(kpi => {
        const kpiDef = getKpiDefinition(kpi.type, getContextOverride(session, settings, { statistic: kpi.statistic }));
        if (!kpiDef)
            return undefined;

        const isQuantityMissing = kpiDef.isQuantityDependent && kpiDef.allowedQuantities.actual.case[0] === undefined;
        const isPlanMissing = kpiDef.requiresPlanningData && !hasPlanningLog;
        const isRequiredEventKeysMissing = kpiDef.requiredEventKeys && kpiDef.requiredEventKeys.some(k => get(session.project, k) === undefined);

        if (!kpiDef || isQuantityMissing || isPlanMissing || isRequiredEventKeysMissing)
            return undefined;

        return {
            kpiType: kpi.type,
            statistic: kpi.statistic,
            quantity: kpiDef.isQuantityDependent ? kpiDef.allowedQuantities.actual.case[0] : undefined,
        } as DashboadTileSettings;
    }).filter(d => d !== undefined).slice(0, 6) as DashboadTileSettings[];

    return result;
}

/**
 * Gets the custom KPIs that are used in the tiles provided
 */
function getCustomKpis(tileDefs: TileModel[] | undefined) {
    return uniqBy(flatMap((tileDefs ?? []).map(def => {
        if (def.kpiDefinition?.timeperiodApi === TimeperiodApis.Event)
            return def.kpiDefinition?.eventOverTimeCustomKpis ?? [];

        return def.kpiDefinition.productCustomKpis ?? [];
    })).filter(e => e !== undefined), e => e!.id) as CustomKpi[];
}

/**
 * Returns a StatsCalculationRequest instance where all properties are true that are used
 * in the tiles provided
 */
function getCalculateOptions(tileDefs: TileModel[] | undefined) {
    const result: { [key: string]: boolean } = {};

    for (const tile of tileDefs ?? []) {
        Object.keys(tile.kpiDefinition.apiParameters ?? {}).forEach(key => {
            const prop = key as keyof StatsCalculationRequest;
            if (isBoolean(tile.kpiDefinition.apiParameters![prop]) &&
                tile.kpiDefinition.apiParameters![prop] === true)
                result[key] = true;
        });
    }

    return result as StatsCalculationRequest;
}

function getLoadingTile(settings: SettingsType, tile: DashboadTileSettings, idx: number) {
    const key = `tile-${tile.kpiType ?? ""}-${tile.quantity ?? ""}-${tile.statistic}-${idx}`;
    return <DashboardTile
        key={key}
        values={[]}
        planValues={[]}
        value={0}
        kpiType={tile.kpiType}
        planValue={0}
        prevValue={0}
        isLoading={true}
        unit={Formatter.defaultUnit}
        scales={Formatter.defaultUnit.getUnits({})}
        isLessBetter={true}
        title={""}
        xTickFrequency={settings.dashboard?.frequency ?? TimePeriodFrequencies.Week}
    />;
}

function getTimeseries(data: AggregatedTimeperiodElementSchema[] | undefined, path: string | undefined, scale: number, times: number[]) {
    if (!path || !data)
        return [];

    return data.map((t, idx) => {
        const value = get(t, path ?? "");

        return {
            y: isNiceNumber(value) ? value * scale : undefined,
            x: times[idx]
        };
    }).filter(a => a !== undefined) as Point[] ?? [];
}


function getDashboardPlanningState(session: SessionType, settings: SettingsType) {
    const planningState = getPlanningState(session);
    // default to showing planning data if it's available and use the setting if it is set.
    const showPlanningData = planningState.hasPlanning && (settings.dashboard?.showPlanningData || settings.dashboard?.showPlanningData === undefined);
    return {
        ...planningState,
        showPlanningData
    };
}


async function fetchDashboardSettings(session: SessionType, settings: SettingsContextType, projectId: string | undefined) {
    if (projectId === undefined)
        return;

    // First render if settings and local storage don't have dashboard data, then display the default dashboard
    if (settings.dashboard === undefined && getDashboardSettings(session, projectId) === undefined) {
        try {
            const viewConfiguration = await Api.getViewConfigurations({
                projectIdEq: projectId,
                viewTypeEq: ViewConfigurationType.Dashboard
            });

            if (viewConfiguration.length > 0) {
                settings.mergeSet({
                    dashboard: viewConfiguration[0].settings.dashboard,
                });
                writeDashboardSettings(session, settings);
                return;
            }

        } catch {
            // ignore
        }
    }

    // If we don't have a default dashboard and we don't have dashboard data in settings, then get it from local storage
    if (settings.dashboard === undefined) {
        const dashboardSettings = getDashboardSettings(session, projectId);
        if (dashboardSettings)
            settings.mergeSet({
                dashboard: dashboardSettings,
            });
        return;
    }

    // Update local storage tile settings
    if (settings.dashboard.tiles)
        writeDashboardSettings(session, settings);
}

async function resetDashboardSettings(session: SessionType, settings: SettingsContextType, projectId: string | undefined) {
    if (session.project === undefined || projectId === undefined)
        return;

    try {
        const viewConfiguration = await Api.getViewConfigurations({
            projectIdEq: projectId,
            viewTypeEq: ViewConfigurationType.Dashboard
        });
        if (viewConfiguration.length > 0) {
            settings.mergeSet({
                dashboard: viewConfiguration[0].settings.dashboard,
            });
            writeDashboardSettings(session, settings);
            return;
        }
    } catch {
        // ignore
    }

    settings.mergeSet({
        dashboard: {
            ...settings.dashboard,
            tiles: getDefaultTiles(session, settings),
        }
    });
}
